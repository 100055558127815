import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "fw-bolder fs-2" }
const _hoisted_9 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_10 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col form-group" }
const _hoisted_13 = ["name", "value"]
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col-4 mb-4" }
const _hoisted_16 = { class: "card bg-warning bg-opacity-25 h-100" }
const _hoisted_17 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_18 = { class: "card-title m-0" }
const _hoisted_19 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0",
  href: "#"
}
const _hoisted_20 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_21 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_22 = {
  key: 1,
  class: "btn bg-black text-white btn-sm btn-active-light-primary disabled",
  target: "_blank"
}
const _hoisted_23 = {
  key: 0,
  class: "col-4 mb-4"
}
const _hoisted_24 = { class: "card bg-warning bg-opacity-25 h-100" }
const _hoisted_25 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_26 = { class: "card-title m-0" }
const _hoisted_27 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0",
  href: "#"
}
const _hoisted_28 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_29 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_30 = ["href"]
const _hoisted_31 = { class: "col-12 mb-4" }
const _hoisted_32 = { class: "card h-100" }
const _hoisted_33 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_34 = { class: "card-title m-0" }
const _hoisted_35 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0",
  href: "#"
}
const _hoisted_36 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_37 = { class: "custom-tree-node" }
const _hoisted_38 = ["href"]
const _hoisted_39 = ["onClick", "title"]
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = { key: 2 }
const _hoisted_43 = { key: 3 }
const _hoisted_44 = { key: 4 }
const _hoisted_45 = { key: 5 }
const _hoisted_46 = { key: 6 }
const _hoisted_47 = { key: 7 }
const _hoisted_48 = { key: 8 }
const _hoisted_49 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.results.title")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.results.desc")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCampaignId) = $event)),
                  class: "form-control form-control-solid"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaigns, (campaign) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: campaign.id,
                      name: campaign.label,
                      value: campaign.id
                    }, _toDisplayString(campaign.label), 9, _hoisted_13))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.selectedCampaignId]
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-primary btn-light",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reloadData()))
                }, _toDisplayString(_ctx.$t("general.search")), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                  _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("pages.results.groupreport")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  (this.groupReportLink)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "btn btn-warning btn-sm btn-light btn-active-light-primary",
                        target: "_blank",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (this.downloadGroupReport()))
                      }, _toDisplayString(_ctx.$t("pages.results.accessResults")), 1))
                    : (_openBlock(), _createElementBlock("a", _hoisted_22, _toDisplayString(_ctx.$t("general.noavailable")), 1))
                ])
              ])
            ])
          ]),
          (this.$store.getters.currentUser.user.userprofile.softconcept)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                      _createElementVNode("a", _hoisted_27, _toDisplayString(_ctx.$t("pages.results.customizewebreporting")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("a", {
                        href: this.$store.getters.currentUser.user.userprofile.softconcept,
                        class: "btn btn-warning btn-sm btn-light btn-active-light-primary",
                        target: "_blank"
                      }, _toDisplayString(_ctx.$t("pages.results.accessResults")), 9, _hoisted_30)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                  _createElementVNode("a", _hoisted_35, _toDisplayString(_ctx.$t("pages.results.entityreport")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_el_tree, {
                  data: _ctx.entities,
                  "default-expanded-keys": _ctx.defaultExpandedKeys,
                  "node-key": "id",
                  ref: "tree",
                  "highlight-current": "",
                  props: _ctx.defaultProps,
                  onNodeExpand: _ctx.handleNodeClick,
                  onNodeCollapse: _ctx.handleNodeClick
                }, {
                  default: _withCtx(({ node, data }) => [
                    _createElementVNode("span", _hoisted_37, [
                      _createElementVNode("span", null, _toDisplayString(node.label), 1),
                      _createElementVNode("span", null, [
                        (data.externalcompanyresults.length > 0)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: data.externalcompanyresults[0].link,
                              target: "_blank",
                              class: "primary-color"
                            }, _toDisplayString(_ctx.$t("pages.results.accessResults")), 9, _hoisted_38))
                          : _createCommentVNode("", true),
                        (data.resultfile)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(data.resultfile, (fileres) => {
                              return (_openBlock(), _createElementBlock("a", {
                                key: fileres.id,
                                onClick: ($event: any) => (_ctx.downloadReport(fileres)),
                                target: "_blank",
                                class: "primary-color",
                                style: {"z-index":"100"},
                                title: fileres.name
                              }, [
                                (fileres.extension === 'pdf')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_40, _cache[6] || (_cache[6] = [
                                      _createElementVNode("i", { class: "fas fa-solid fa-file-pdf me-3 fs-5" }, null, -1)
                                    ])))
                                  : (fileres.extension === 'xlsx')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_41, _cache[7] || (_cache[7] = [
                                        _createElementVNode("i", { class: "fas fa-solid fa-file-excel me-3 fs-5" }, null, -1)
                                      ])))
                                    : (fileres.extension === 'csv')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_42, _cache[8] || (_cache[8] = [
                                          _createElementVNode("i", { class: "fas fa-solid fa-file-csv me-3 fs-5" }, null, -1)
                                        ])))
                                      : (fileres.extension === 'txt')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_43, _cache[9] || (_cache[9] = [
                                            _createElementVNode("i", { class: "fas fa-solid fa-file-alt me-3 fs-5" }, null, -1)
                                          ])))
                                        : (fileres.extension === 'pptx')
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_44, _cache[10] || (_cache[10] = [
                                              _createElementVNode("i", { class: "fas fa-solid fa-file-powerpoint me-3 fs-5" }, null, -1)
                                            ])))
                                          : (fileres.extension === 'docx')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_45, _cache[11] || (_cache[11] = [
                                                _createElementVNode("i", { class: "fas fa-sharp fa-solid fa-file-word me-3 fs-5" }, null, -1)
                                              ])))
                                            : (fileres.extension === 'doc')
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_46, _cache[12] || (_cache[12] = [
                                                  _createElementVNode("i", { class: "fas fa-sharp fa-solid fa-file-word me-3 fs-5" }, null, -1)
                                                ])))
                                              : (fileres.extension === 'jpeg' || fileres.extension === 'jpg')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_47, _cache[13] || (_cache[13] = [
                                                    _createElementVNode("i", { class: "fas fa-sharp fa-solid fa-file-image me-3 fs-5" }, null, -1)
                                                  ])))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(_ctx.$t("pages.results.download")) + " " + _toDisplayString(fileres.extension), 1))
                              ], 8, _hoisted_39))
                            }), 128))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t("pages.results.notAvailable")), 1)
                            ], 64))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["data", "default-expanded-keys", "props", "onNodeExpand", "onNodeCollapse"])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[14] || (_cache[14] = [
          _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
            _createElementVNode("span", {
              class: "spinner-border text-primary",
              role: "status"
            })
          ], -1)
        ])))
  ]))
}